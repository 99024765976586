import React from 'react';
import { compose } from 'redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormattedMessage as Translation } from 'react-intl';
import { withConfig } from 'HOCs/withConfig/withConfig';
import withSearches from 'HOCs/withSearches/withSearches';
import css from './PopularSearches.scss';
import LinksSearch from './LinksSearch';

export const NavPopularSearches = ({ searches, marketConfig }) => {
    if (!searches || !searches.length) {
        return null;
    }

    const isMXCL = marketConfig.get('olxAutos', 'isMXCL');

    return (
        <div
            className={ classNames(
                css.popularSearches, {
                    [css.mxPopularSearches]: isMXCL
                }) }
            data-aut-id="popularSearches"
            id="popular-searches"
        >
            <span className={ css.title }>
                <Translation key="relatedSearches" id="relatedSearches" />:
            </span>
            <ul>
                <LinksSearch
                    searches={ searches }
                    isMXCL={ isMXCL }
                />
            </ul>
        </div>
    );
};

NavPopularSearches.propTypes = {
    searches: PropTypes.array,
    marketConfig: PropTypes.shape({
        get: PropTypes.func.isRequired
    }).isRequired
};

NavPopularSearches.defaultProps = {
    searches: []
};

export default compose(
    withSearches,
    withConfig('marketConfig'),
    React.memo
)(NavPopularSearches);
