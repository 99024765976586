/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ImageWrapper from 'Components/ImageWrapper/ImageWrapper';
import { SVG_TYPE } from 'Constants/images';
import withConfig from 'HOCs/withConfig/withConfig';
import css from './VasTag.APP_TARGET.scss';

export const VasTag = ({
    vasImageUrl,
    vasTitle,
    onVasTagTap: onVasTap,
    showSeparator,
    config,
    visualizationType
}) => {
    const onVasTagTap = e => {
        onVasTap(e, vasTitle);
    };

    const isMXCL = config.get('olxAutos', 'isMXCL');

    return (
        <div className={ css.tag }
            data-tag
            onClick={ onVasTagTap }
        >
            <div className={ classNames(css.tagContainer, {
                [css.separator]: isMXCL && showSeparator
            }) }
            >
                <ImageWrapper
                    fileName={ vasImageUrl || '' }
                    types={ SVG_TYPE }
                    className={ css.tagImg } />
                <div className={ classNames(css.tagTitle, { [css.trim]: visualizationType === 'smallGrid' }) } title={ vasTitle }>
                    {vasTitle}
                </div>
            </div>
        </div>
    );
};

VasTag.propTypes = {
    vasImageUrl: PropTypes.string,
    vasTitle: PropTypes.string,
    onVasTagTap: PropTypes.func,
    showSeparator: PropTypes.bool,
    config: PropTypes.shape({
        get: PropTypes.func.isRequired
    }).isRequired,
    visualizationType: PropTypes.string
};

export default withConfig(React.memo(VasTag));
