import React from 'react';
import PropTypes from 'prop-types';

import NavPopularSearches from '../PopularSearches/NavPopularSearches.APP_TARGET';
import Breadcrumb from 'Components/Breadcrumb/Breadcrumb';
import Title from '../Title';
import css from './Nav.desktop.scss';
import { LISTING } from 'Constants/tracking';
import BannerImage from 'Components/BannerImage/BannerImage';

const Nav = ({ banner, showBannerInListing, showPopularSearches, links, showListingCarousel, widgetToFetch, isClassified }) => (
    <React.Fragment>
        { showPopularSearches && <NavPopularSearches /> }
        { showListingCarousel && <BannerImage listingPageCarousel={ true } widgetToFetch={ widgetToFetch } frmPage={ LISTING } />}
        { isClassified && banner }
        { showBannerInListing && <BannerImage frmPage={ LISTING } />}
        <Breadcrumb
            className={ css.breadcrumb }
            links={ links }
        />
        <h1 className={ css.title }>
            <Title />
        </h1>
    </React.Fragment>
);

Nav.propTypes = {
    banner: PropTypes.node,
    links: PropTypes.array,
    showBannerInListing: PropTypes.bool,
    showPopularSearches: PropTypes.bool,
    showListingCarousel: PropTypes.bool,
    widgetToFetch: PropTypes.string,
    isClassified: PropTypes.bool
};

export default Nav;
