export const Home = {
    href: '/',
    translation: {
        id: 'home'
    },
    key: 'home'
};

export const Category = {
    href: '/post',
    translation: {
        id: 'category'
    },
    key: 'category'
};

export const ProvideDetails = {
    href: '/post/attributes',
    translation: {
        id: 'provide_details'
    },
    key: 'provide_details'
};

export const ReviewDetails = {
    href: '/post/attributes#review',
    translation: {
        id: 'reviewDetails'
    },
    key: 'reviewDetails'
};

export const STEPKEY = {
    PROVIDEDETAILS: 'provide_details',
    REVIEWDETAILS: 'reviewDetails',
    CATEGORY: 'category'
};
