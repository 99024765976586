import { createSelector } from 'reselect';
import { buildURL } from 'Helpers/url';
import { decodeSearch, camelize } from 'Helpers/strings';
import { parsePathname } from 'Helpers/listing';
import { getFilterHeirarchy } from 'Helpers/filters';
import { getCategoryHeirarchy } from 'Helpers/categories';
import { Home } from 'Constants/breadcrumb';
import { getAppliedFilters } from './filters';
import { slugify } from 'Helpers/strings';
import { getProjectId } from 'Selectors/projects';
import { getLocationHeirarchy } from 'Helpers/locations';

const createBuildURL = config => arg => buildURL(arg, {
    useFakeLevelSlug: config.get('SEO', 'useFakeLevelSlug'),
    categoryCover: config.get('categoryCover')
}, !!config.get('isOlxMobbi'));

export const listingBreadcrumbSelector = createSelector(

    (_, { marketConfig }) => marketConfig,
    state => state.categories.elements, // flatCategoryMap
    (_, { location }) => location,
    (_, { selectedLocation }) => selectedLocation && selectedLocation.addressComponents,
    (_, { params: { categoryID }}) => categoryID,
    (_, { params: { text }}) => decodeSearch(text), // decodedText
    getAppliedFilters, // appliedFilters
    (config, flatCategoryMap, location, selectedLocations, categoryID, decodedText, appliedFilters) => {
        const buildURLWithConfig = createBuildURL(config);
        const parsedLocation = parsePathname(location.pathname);
        const seoFriendlyBreadcrumb = config.get('seoFriendlyBreadcrumb');

        const breadcrumbs = [
            { ...Home }
        ];

        if (selectedLocations && selectedLocations[0] && selectedLocations[0].type === 'COUNTRY') {
            selectedLocations = selectedLocations.reverse();
        }

        if (categoryID) {
            breadcrumbs.push(...getCategoryHeirarchy(flatCategoryMap, categoryID, selectedLocations ? null : parsedLocation, config));

            if (!seoFriendlyBreadcrumb) {
                breadcrumbs.push(...getLocationHeirarchy(selectedLocations, flatCategoryMap[categoryID], config));
            }

            breadcrumbs.push(...getFilterHeirarchy(
                appliedFilters,
                filter => buildURLWithConfig({
                    category: flatCategoryMap[categoryID],
                    location: seoFriendlyBreadcrumb ? undefined : parsedLocation,
                    config,
                    params: {
                        filter
                    }
                }),
                config
            ));

            // Moves location to the end of breadcrumbs
            if (seoFriendlyBreadcrumb) {
                breadcrumbs.push(...getLocationHeirarchy(selectedLocations && selectedLocations.filter((n, idx) => idx < 2), flatCategoryMap[categoryID], config));
            }

            if (seoFriendlyBreadcrumb && breadcrumbs.length) {
                delete breadcrumbs[breadcrumbs.length - 1].href;
            }
        }

        if (decodedText) {
            breadcrumbs.push({
                text: camelize(decodedText)
            });
        }
        else if (location.pathname.includes('items')) {
            breadcrumbs.push({
                translation: { id: 'allResults' }
            });
        }

        if (!seoFriendlyBreadcrumb) {
            breadcrumbs.pop();
        }

        return breadcrumbs;
    }
);

export const itemBreadcrumbSelector = createSelector(
    (_, { marketConfig }) => marketConfig,
    (state, props) => state.items.elements[props.params.id],
    state => state.queryState, // TODO: use selectedLocation!
    state => state.categories.elements,
    (config, item, queryState, categories) => {
        const buildURLWithConfig = createBuildURL(config);
        const seoFriendlyBreadcrumb = config.get('seoFriendlyBreadcrumb');

        const breadcrumbs = [
            { ...Home }
        ];

        if (queryState && queryState.location) {
            const location = queryState.location;

            breadcrumbs.push({
                translation: {
                    id: 'adsInLocation',
                    values: location
                },
                href: buildURLWithConfig({ location })
            });
        }

        if (item) {
            if (item.category_id) {
                const locations = item.metadata && item.metadata.locations && item.metadata.locations[0] && item.metadata.locations[0].tree;

                breadcrumbs.push(...getCategoryHeirarchy(categories, item.category_id, null, config));

                if (!(seoFriendlyBreadcrumb && seoFriendlyBreadcrumb.hideLocation)) {
                    breadcrumbs.push(...getLocationHeirarchy(locations, categories[item.category_id], config));
                }

                breadcrumbs.push(...getFilterHeirarchy(item.parameters, filter => buildURLWithConfig({
                    category: categories[item.category_id],
                    location: locations && locations[0],
                    params: {
                        filter
                    }
                }), config, locations && locations[0]));
            }

            if (item.title) {
                breadcrumbs.push({
                    text: item.title
                });
            }
        }

        if (!seoFriendlyBreadcrumb && breadcrumbs.length) {
            delete breadcrumbs[breadcrumbs.length - 1].href;
        }

        return breadcrumbs;
    }
);

export const reProjectBreadcrumbSelector = createSelector(
    (_, { marketConfig }) => marketConfig,
    (state, props) => state.projects && state.projects.elements[getProjectId(props)],
    state => state.categories && state.categories.elements,
    state => state.locations && state.locations.selectedLocation,
    (config, project, categories, location) => {
        const breadcrumbs = [
            Home
        ];

        const categoryId = config.get('reProjects', 'categoryMapping');

        if (categories && categoryId) {
            breadcrumbs.push(...getCategoryHeirarchy(categories, categoryId));
        }

        if (project) {
            const item = project.parameters && project.parameters.filter(params => params.key === 'construction_status');
            const status = item && item[0] && item[0].value;

            if (status) {
                breadcrumbs.push({
                    text: status,
                    href: `/projects/${slugify(location.name)}_g${location.id}/${slugify(status)}`
                });
            }
        }
        return breadcrumbs;
    }
);
