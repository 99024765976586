import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { Link } from 'panamera-react-ui';

import { scrollToTop } from 'Helpers/page';
import css from './PopularSearches.scss';
import { MAX_POPULAR_SEARCHES } from 'Constants/popularSearches';
import { setItem as setSessionStorageItem } from 'Helpers/sessionStorage';
import withTrack from 'HOCs/withTrack/withTrack';
import { POPULAR_SEARCH, SEARCH_TYPE } from 'Constants/tracking';

const LinksSearch = ({ searches, isMXCL, track }) => {
    const slicedSearches = searches
        .slice(0, MAX_POPULAR_SEARCHES);

    const Links = slicedSearches.map(({ term, href }, index) => {
        function clickHandler() {
            if (isMXCL) {
                track(POPULAR_SEARCH, { chosen_option: term });
                setSessionStorageItem('search_type', SEARCH_TYPE.POPULAR_SEARCH);
            }
            scrollToTop();
        }

        const link = (<li key={ term }>
            <Link
                className={ css.links }
                to={ href }
                onClick={ clickHandler }
            >
                {term}
            </Link>
        </li>);

        if (isMXCL || index === slicedSearches.length - 1) {
            return link;
        }

        return [
            link,
            <li key={ `separator-${index}` }>-</li>
        ];
    });

    return Links;
};

LinksSearch.propTypes = {
    searches: PropTypes.array.isRequired,
    isMXCL: PropTypes.bool
};

export default compose(
    withTrack,
    React.memo
)(LinksSearch);
