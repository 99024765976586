import React from 'react';
import { connect } from 'react-redux';
import setServerContext from 'Actions/serverContext';
import PropTypes from 'prop-types';

class ServerContext extends React.PureComponent {
    static propTypes = {
        setServerContext: PropTypes.func.isRequired,
        children: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.array,
            PropTypes.string
        ])
    }
    constructor(props) {
        super(props);
        if (typeof window === 'undefined') {
            // eslint-disable-next-line no-unused-vars
            const { children, setServerContext, ...otherProps } = props;

            props.setServerContext(otherProps);
        }
    }

    render() {
        return this.props.children || null;
    }
}

const mapDispatchToProps = {
    setServerContext
};

export default connect(null, mapDispatchToProps)(ServerContext);
