import '@olxid/olx-autos-landing-page/dist/Components/Bundle/Bundle.scss';
import '@olxid/olx-autos-landing-page/dist/Components/Bundle/SliderArrow/SliderArrow.scss';
import '@olxid/olx-autos-landing-page/dist/Components/EmptyCarComponent/EmptyCarComponent.scss';
import '@olxid/olx-autos-landing-page/dist/Components/ShimmerCarouselCard/ShimmerCrouselCard.scss';
import '@olxid/olx-autos-landing-page/dist/Components/SkeletonLoader/SkeletonLoader.scss';
import '@olxid/olx-autos-landing-page/dist/Components/Title/Title.scss';
import '@olxid/olx-autos-landing-page/dist/Components/Tabs/Tabs.scss';
import '@olxid/olx-autos-landing-page/dist/Widgets/TabsCarousel/TabsCarousel.scss';
import React, { useMemo } from 'react';
import css from './ZrpWidget.scss';
import { TabsCarousel, ComponentDependencyProvider, Bundle } from 'olx-autos-landing-page';
import { IS_DESKTOP } from 'Constants/device.APP_TARGET';
import VasSheetAsync from 'Components/VasSheet/VasSheetAsync';
import { ConfigContext } from 'Components/ConfigProvider/ConfigContext';
import ItemCard from 'Components/Listing/listingMXComponents/ItemCard/ItemCard';
import EmptyCenter from 'Autos/components/EmptyCenter/EmptyCenter';
import ReactObserver from 'Components/ReactObserver/ReactObserver';
import OLXAutosWrapper from '../../olxautos/OLXAutosWrapper';
import { FormattedMessage as Translation } from 'react-intl';
import PropTypes from 'prop-types';
import { withConfig } from 'HOCs/withConfig/withConfig';

// eslint-disable-next-line no-new
new OLXAutosWrapper();
const ComponentDependency = { ConfigContext, EmptyCenter, ItemCard, VasSheetAsync, ReactObserver };

const ZrpWidget = ({ zrpWidgetConfig, track, marketConfig }) => {
    const SLIDES_TO_SHOW_DESKTOP = 4.5;
    const SLIDES_TO_SHOW_MOBILE = 2;
    const { apiUrl, bundleType, order, data = {}, showCount = 0 } = zrpWidgetConfig || {};
    const isID = marketConfig.get('siteCode') === 'olxid';

    const createShowAllUrl = (type, apiParams) => {
        const filterUrl = '/autos_c84?filter=';

        switch (type) {
            case 'year': {
                return `${filterUrl}year_between_${apiParams.year_min}_to_${apiParams.year_max}`;
            }
            case 'price': {
                return `${filterUrl}price_between_${apiParams.price_min}_to_${apiParams.price_max}`;
            }
            case 'brand': {
                return `${filterUrl}make_eq_${apiParams.make}`;
            }
            case 'recent':
            default: return `${filterUrl}olxautos_listing_eq_true`;
        }
    };

    const createShowAllUrlForID = (type, apiParams) => {
        const filterUrl = '/mobil-bekas_c198?filter=';

        switch (type) {
            case 'year': {
                return `${filterUrl}m_year_between_${apiParams.m_year_min}_to_${apiParams.m_year_max}`;
            }
            case 'price': {
                return `${filterUrl}price_between_${apiParams.price_min}_to_${apiParams.price_max}`;
            }
            case 'brand': {
                return `${filterUrl}make_eq_${apiParams.make}`;
            }
            case 'recent':
            default: return `${filterUrl}olxautos_listing_eq_true`;
        }
    };

    useMemo(() => {
        Object.keys(data).forEach(element => {
            data[element].tabs.forEach(tab => {
                if (element === 'year') {
                    if (!tab.apiParams.year_max) {
                        const currentYear = new Date().getFullYear();

                        if (isID) {
                            tab.apiParams.m_year_max = currentYear;
                            tab.apiParams.m_year_min = currentYear - tab.apiParams.year_min + 1;
                        }
                        else {
                            tab.apiParams.year_max = currentYear;
                            tab.apiParams.year_min = currentYear - tab.apiParams.year_min + 1;
                        }
                    }
                }
                tab.apiParams.min = 1;
                tab.showAll = isID ? createShowAllUrlForID(element, tab.apiParams) : createShowAllUrl(element, tab.apiParams);
            });
        });
    }, [data, isID]);

    return (<div className={ css.zrpWidgetContainer }>
        <ComponentDependencyProvider value={ ComponentDependency }>
            {order?.slice(0, showCount).map(type => {
                return (<div data-aut-id="carousel-container" key={ type } className={ css.noResultCarouselContainer }>
                    <div className={ css.tabsTitle } data-aut-id="carousel-title"><Translation id={ `${type}Heading` } /></div>
                    {type === 'recent' ? <Bundle
                        url={ apiUrl }
                        bundleType={ bundleType }
                        count={ IS_DESKTOP
                            ? SLIDES_TO_SHOW_DESKTOP
                            : SLIDES_TO_SHOW_MOBILE }
                        showAllUrl={ createShowAllUrl(type) }
                        track={ track }
                        zrpWidget={ true }
                        source="bundleCard"
                        hideShowAllLink={ true }
                        widgetKey="no-result-widget"
                    /> : <TabsCarousel
                        // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
                        data={ {
                            ...data[type], apiUrl, bundleType,
                            hideShowAllLink: true,
                            count: IS_DESKTOP ? SLIDES_TO_SHOW_DESKTOP : SLIDES_TO_SHOW_MOBILE,
                            source: 'bundleCard',
                            tabLeftAligned: true,
                            zrpWidget: true
                        } }
                        track={ track }
                        name="no-result-widget"
                        widgetType={ type }
                    />}
                </div>);
            })}
        </ComponentDependencyProvider>
    </div>
    );
};

ZrpWidget.propTypes = {
    track: PropTypes.func,
    zrpWidgetConfig: PropTypes.object,
    marketConfig: PropTypes.object
};

export default withConfig('marketConfig')(ZrpWidget);
